import type {
  DistinctFacetParameter,
  EntitySortingParameter,
  RangeFacetParameter,
  SearchRequest,
} from "@xxl/search-api";
import { EntitySortingParameterCustomNameEnum } from "@xxl/search-api";
import { FACET_CATEGORY_ID } from "./Constants";
import { AbstractSearchFetchStrategy } from "./SearchFetchStrategy";
import type { State } from "./SearchState";

export class GetSearchFetchStrategy extends AbstractSearchFetchStrategy {
  hasCategoryFacet = (searchRequest: SearchRequest): boolean => {
    if (
      Array.isArray(searchRequest.facets) &&
      searchRequest.facets.length > 0
    ) {
      return [...searchRequest.facets].some(
        (facet) => facet.attributeName === FACET_CATEGORY_ID
      );
    }
    return false;
  };

  convertCampaignSortIfCategorySelected = (
    sort: EntitySortingParameter,
    searchRequest: SearchRequest
  ): EntitySortingParameter => {
    if (
      sort.customName === EntitySortingParameterCustomNameEnum.campaignPage &&
      this.hasCategoryFacet(searchRequest)
    ) {
      const campaignCategorySort: EntitySortingParameter = {
        ...sort,
        customName: EntitySortingParameterCustomNameEnum.campaignCategory,
      };
      return campaignCategorySort;
    }
    return sort;
  };

  getFacetItemsAsQueryStringArray = (
    facet: DistinctFacetParameter | RangeFacetParameter
  ): string | undefined => {
    const facetItems: string[] = [facet]
      .flatMap(
        (facetParameter: DistinctFacetParameter | RangeFacetParameter) => {
          if (facetParameter.selected != null) {
            if (Array.isArray(facetParameter.selected)) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const distinctFacetParameterSelected = (
                facetParameter as DistinctFacetParameter
              ).selected!;
              return distinctFacetParameterSelected
                .filter((entry) => entry)
                .map((entry) => `(${entry})`);
            }
          }
          return null;
        }
      )
      .filter((entry) => entry) as string[];

    if (facetItems.length !== 0) {
      return `${facet.attributeName}=${[...facetItems].sort()}`;
    }
    return undefined;
  };

  doFetchProducts = async (
    siteId: string,
    searchRequests: SearchRequest[],
    userId?: string
  ) => {
    const options = this.getFetchProductsQueryOptions(userId);
    return Promise.all(
      searchRequests.map((searchRequest) => {
        const facets =
          Array.isArray(searchRequest.facets) && searchRequest.facets.length > 0
            ? [...searchRequest.facets]
                .sort((facet1, facet2) =>
                  facet1.attributeName.localeCompare(facet2.attributeName)
                )
                .map((facet) => this.getFacetItemsAsQueryStringArray(facet))
                .filter(Boolean)
                .map((facet) => facet as string)
            : undefined;

        const sortBy =
          Array.isArray(searchRequest.sortBy) && searchRequest.sortBy.length > 0
            ? [...searchRequest.sortBy]
                .map((sort) =>
                  this.convertCampaignSortIfCategorySelected(
                    sort,
                    searchRequest
                  )
                )
                .map((sort) => sort.customName?.toString() ?? "")
                .filter((entry) => entry)
            : undefined;

        return this.searchApi.searchProxy(
          siteId,
          searchRequest.query,
          searchRequest.provider,
          searchRequest.skip?.toString(),
          searchRequest.take?.toString(),
          searchRequest.usergroupids,
          sortBy,
          facets,
          options
        );
      })
    );
  };

  accepts = (state: State): boolean => {
    const emptySearchQuery =
      state.attribute == null && (!state.query || state.query.length === 0);
    const forcePost =
      state.cacheableCampaignSearchQuery && !state.forceSolrAsProvider;
    return !forcePost && (emptySearchQuery || Boolean(state.campaignId));
  };
}
