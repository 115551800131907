import styled from "@emotion/styled/macro";

const Section = styled.section<{ color?: string }>`
  ${({ color }) => {
    if (color === undefined) {
      return "";
    }
    return `
      &::after {
        border-color: ${color} transparent;
      }
    `;
  }}
`;

export { Section };
