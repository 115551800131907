import { useMemo } from "react";
import { getFilteredSmallBanners } from "../SearchContentHelper";
import type {
  CampaignLevel1CategoryData,
  ProductListingSmallBannerData,
} from "@xxl/frontend-api";
import { isNotNullOrUndefined } from "@xxl/common-utils";

const useFilteredSmallBanners = ({
  categoryProductListingSmallBanner,
  level1Categories,
  productListingSmallBanner,
  productListingSmallBannersIds,
  selectedCategoryCode,
}: {
  categoryProductListingSmallBanner?: ProductListingSmallBannerData[] | null;
  level1Categories?: CampaignLevel1CategoryData[];
  productListingSmallBanner?: ProductListingSmallBannerData[] | null;
  productListingSmallBannersIds?: string[];
  selectedCategoryCode: string | null;
}) =>
  useMemo(
    () =>
      isNotNullOrUndefined(productListingSmallBanner)
        ? getFilteredSmallBanners(
            productListingSmallBanner,
            selectedCategoryCode,
            level1Categories,
            productListingSmallBannersIds
          )
        : categoryProductListingSmallBanner ?? [],
    [
      categoryProductListingSmallBanner,
      level1Categories,
      productListingSmallBanner,
      productListingSmallBannersIds,
      selectedCategoryCode,
    ]
  );

export { useFilteredSmallBanners };
