import React, { useCallback, useMemo, useRef, useState } from "react";
import ReactHtmlParser from "html-react-parser";
import useResizeObserver from "@react-hook/resize-observer";
import type { TruncatedTextProps } from "./types";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { CaretIcon } from "../Common/Icons/Caret";
import { Button, TextWrapper } from "./TruncateText.styled";
import { checkIfStringHasHtmlTags } from "../../utils/xxl-string";

export const TruncateText = ({
  numberOfVisibleRows,
  text,
}: TruncatedTextProps) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const [state, setState] = useState<"initial" | "truncated" | "expanded">(
    "initial"
  );
  const { t } = useTranslations();

  const resizeCallback = useCallback(() => {
    if (ref.current === null || state === "expanded") {
      return;
    }
    const { scrollHeight = 0 } = ref.current;
    const { height } = ref.current.getBoundingClientRect();
    // Height might be floating point, let's round value
    const roundedHeight = Math.round(height);
    const isTextOverflowing = scrollHeight > roundedHeight;
    setState(isTextOverflowing ? "truncated" : "initial");
  }, [state]);
  useResizeObserver(ref, resizeCallback);

  const Text = useMemo(
    () =>
      TextWrapper.withComponent(checkIfStringHasHtmlTags(text) ? "div" : "p"),
    [text]
  );

  return (
    <>
      <Text
        numberOfVisibleRows={numberOfVisibleRows}
        onClick={() => setState("expanded")}
        ref={ref}
        shouldTruncate={state !== "expanded"}
        data-testid="truncate-text"
      >
        {ReactHtmlParser(text)}
      </Text>
      {state === "truncated" && (
        <Button onClick={() => setState("expanded")}>
          {t("read.more")}
          <CaretIcon direction={"down"} />
        </Button>
      )}
    </>
  );
};
