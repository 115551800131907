import type { Dispatch } from "react";
import { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { UPDATE_SORTS_FOR_LOGIN_STATUS } from "../Constants";
import { SORTS_FOR_NON_REGISTERED, SORTS_FOR_REGISTERED } from "../../Common";
import type { Action } from "../SearchState";
import type { SortData } from "../../Common/Model/SortData";

const useSortsForLoginStatus = ({
  defaultSorts,
  dispatch,
  userGroups,
}: {
  defaultSorts: SortData[];
  dispatch: Dispatch<Action>;
  userGroups?: string;
}) => {
  useEffect(() => {
    const sortsForLoginStatus = isEmpty(userGroups)
      ? SORTS_FOR_NON_REGISTERED
      : SORTS_FOR_REGISTERED;
    dispatch({
      type: UPDATE_SORTS_FOR_LOGIN_STATUS,
      payload: [...defaultSorts, ...sortsForLoginStatus],
    });
  }, [defaultSorts, dispatch, userGroups]);
};

export { useSortsForLoginStatus };
