import type { SearchApi, SearchRequest, SearchResponse } from "@xxl/search-api";
import type { State } from "./SearchState";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import { LOOP_API_VERSION } from "./Constants";

export interface SearchFetchStrategy {
  accepts(state: State): boolean;

  doFetchProducts(
    siteId: string,
    searchRequests: SearchRequest[],
    userId?: string
  ): Promise<AxiosResponse<SearchResponse>[]>;
}

export abstract class AbstractSearchFetchStrategy
  implements SearchFetchStrategy
{
  private readonly _searchApi: SearchApi;

  constructor(searchApi: SearchApi) {
    this._searchApi = searchApi;
  }

  abstract accepts(state: State): boolean;

  abstract doFetchProducts(
    siteId: string,
    searchRequests: SearchRequest[],
    userId?: string
  ): Promise<AxiosResponse<SearchResponse>[]>;

  get searchApi(): SearchApi {
    return this._searchApi;
  }

  getFetchProductsQueryOptions = (userId?: string): AxiosRequestConfig => {
    return {
      headers: {
        "api-version": LOOP_API_VERSION,
        ...(userId && { "user-id": userId }),
      },
    };
  };
}
