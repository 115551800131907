import type { ProductData as ProductDataSearch } from "@xxl/search-api";
import React from "react";
import { useSharedData } from "../../contexts/SharedData";
import type { ProductMetaData } from "../../global";
import { saveCurrentScrollPosition } from "../Search/SearchScrollHelper";
import { useSearchContext } from "../Search/SearchState";
import { DEFAULT_GRID_VALUE } from "./constants";
import {
  CardButtonIcons,
  ProductLink,
  QuickShopButton,
} from "./Product.styled";
import { getOptionalRelativeHref, trackProductClick } from "./product-helper";
import { ProductImage } from "./ProductImage";
import { MobileImageContainer } from "../ProductList/ProductListGridExtras.styled";
import { ProductCardRibbons } from "./ProductCardRibbons";
import { FavoriteProductHeart } from "../Favorites/FavoriteProductHeart";
import { useTracking } from "../../contexts/Tracking";
import { useQuickshop } from "react-app/src/hooks/useQuickshop/useQuickshop";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { Cart } from "@xxl/icons";

interface ImageProductCardProps {
  product: ProductDataSearch;
  productMetaData: ProductMetaData;
  selectedColumnsNumber: number;
}

export const ImageProductCard: React.FunctionComponent<
  ImageProductCardProps
> = ({
  product,
  productMetaData,
  selectedColumnsNumber = DEFAULT_GRID_VALUE,
}) => {
  const { state } = useSearchContext();
  const { selectedFilters } = state;
  const {
    featureToggles: { toggle_favorites },
  } = useSharedData().data;
  const { t } = useTranslations();
  const { showQuickshop, openQuickshop } = useQuickshop();
  const trackers = useTracking();

  const productCode = product.code ?? "";
  const handleClick = (): void => {
    trackProductClick(trackers, product, productMetaData);
    saveCurrentScrollPosition(document.documentElement.scrollTop);
  };

  return (
    <MobileImageContainer>
      <ProductLink
        href={getOptionalRelativeHref({
          product,
          selectedFilters,
        })}
        onClick={handleClick}
        data-id={productCode}
        data-testid={`image-product-card-link-${productCode}`}
      >
        <ProductImage
          imageUrl={product.primaryImage}
          alt={product.name}
          columnAmount={selectedColumnsNumber}
        />
        <ProductCardRibbons product={product} />
      </ProductLink>
      <CardButtonIcons>
        {toggle_favorites && (
          <FavoriteProductHeart productStyle={product.code ?? ""} />
        )}
        {showQuickshop && (
          <QuickShopButton
            onClick={(e) => openQuickshop(e, productCode)}
            aria-label={t("product.details.add.to.cart.label")}
            title={t("product.details.add.to.cart.label")}
            data-testid="quickshop-button"
            className="quickshop-button"
          >
            <Cart />
          </QuickShopButton>
        )}
      </CardButtonIcons>
    </MobileImageContainer>
  );
};
