import type { Dispatch } from "react";
import { useEffect } from "react";
import {
  addXXLEventListener,
  removeXXLEventListener,
  XXL_LOGIN_EVENT,
} from "../../../utils/xxl-event";
import { USER_LOGGED_IN } from "../../../components/Search/Constants";
import { saveCurrentScrollPosition } from "../../../components/Search/SearchScrollHelper";
import { flushSync } from "react-dom";
import type { XxlLoginEventPayload } from "react-app/src/global";
import type { Action } from "../SearchState";

const useLoginCallback = ({ dispatch }: { dispatch: Dispatch<Action> }) => {
  useEffect(() => {
    const userLoginCallback = ({
      detail: { userId, userGroups },
    }: CustomEvent<XxlLoginEventPayload>): void => {
      saveCurrentScrollPosition(window.scrollY);
      flushSync(() => {
        dispatch({
          type: USER_LOGGED_IN,
          payload: { userId, userGroups },
        });
      });
    };
    addXXLEventListener(XXL_LOGIN_EVENT, userLoginCallback);
    return () => removeXXLEventListener(XXL_LOGIN_EVENT, userLoginCallback);
  }, [dispatch]);
};

export { useLoginCallback };
