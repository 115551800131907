import * as React from "react";
import type { LongTailTitleProps, LongTailTitleFacet } from "./LongTailTitle";
import { LongTailFacetType } from "./LongTailTitle";
import { LongTailTitleItem } from "./LongTailTitleItem";
import {
  getLongTailFacetConfiguration,
  getLongTailTitlePartsInOrder,
} from "./LongTailTitleHelper";

type LongTailTitleCompProps = {
  longTailTitle: LongTailTitleProps;
  brandUrl?: string;
};

export const LongTailTitleComp: React.FunctionComponent<
  LongTailTitleCompProps
> = ({ longTailTitle }) => {
  const configuration: LongTailFacetType[] = getLongTailFacetConfiguration();

  const longTailTitleFacets: LongTailTitleFacet[] =
    getLongTailTitlePartsInOrder(longTailTitle, configuration);

  return (
    <h1 className="long-tail-title">
      {longTailTitleFacets.map((comp) => (
        <LongTailTitleItem key={LongTailFacetType[comp.type]} {...comp} />
      ))}
    </h1>
  );
};
