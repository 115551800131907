import type { GridCampaignCarousel } from "@xxl/content-api";
import { ArrowRight } from "@xxl/icons";
import type { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import type { ProductData as ProductDataSearch } from "@xxl/search-api";
import isEmpty from "lodash/isEmpty";
import type { ReactElement } from "react";
import React from "react";
import type { PageType } from "../../constants";
import type { ProductMetaData } from "../../global";
import { Slider } from "../Common/Slider";
import { GRID_COLUMNS } from "../Filter/FilterBar/constants";
import { CarouselProductCard } from "../Product/CarouselProductCard";
import { ImageProductCard } from "../Product/ImageProductCard";
import type { PriceWithLabelsProps } from "../Product/PriceWithLabels/PriceWithLabels";
import type { CombinedProductData } from "../Product/product-helper";
import {
  CampaignCarouselWrapper,
  CarouselContainer,
  HeadingInfoWrapper,
  HeadingMainInfoWrapper,
  HeadingWrapper,
  Headline,
  ImageCarouselContainer,
  Information,
  LabelBox,
  Link,
} from "./ProductListGridExtras.styled";

export const CAMPAIGN_CAROUSEL_TEST_ID = "campaign-carousel";

type CampaignCarouselProps = {
  currentProductIndex: number;
  isMobile: boolean;
  nrOfProductsInRow: number;
  pageType: PageType;
  positionForCampaignCarousel: number;
  PriceComponents: ReactElement<PriceWithLabelsProps>[];
  products: CombinedProductData[];
  selectedColumnsNumber: number;
  slidesPerView: number;
  strategy: RecommendationsParametersStrategyEnum;
  campaignCarouselProductsData?: GridCampaignCarousel;
};

export const CampaignCarousel: React.FunctionComponent<
  CampaignCarouselProps
> = ({
  campaignCarouselProductsData,
  currentProductIndex,
  isMobile,
  nrOfProductsInRow,
  pageType,
  positionForCampaignCarousel,
  PriceComponents,
  products,
  selectedColumnsNumber,
  slidesPerView,
  strategy,
}) => {
  const { campaignImage, label, information, title, link } =
    campaignCarouselProductsData ?? {};
  const Container = isMobile ? ImageCarouselContainer : CarouselContainer;
  const ProductCard = isMobile ? ImageProductCard : CarouselProductCard;
  return (
    <CampaignCarouselWrapper
      data-testid={CAMPAIGN_CAROUSEL_TEST_ID}
      key={`${currentProductIndex}-campaign-carousel`}
      columnAmount={nrOfProductsInRow}
      carouselType={strategy}
      productIndex={positionForCampaignCarousel}
    >
      <HeadingWrapper
        mobileImageUrl={campaignImage?.mobileImageUrl}
        desktopImageUrl={campaignImage?.desktopImageUrl}
      >
        <HeadingMainInfoWrapper>
          {!isEmpty(label?.trim()) ? (
            <LabelBox
              color={campaignImage?.colorTheme.value}
              backgroundColor={campaignImage?.colorTheme.font}
            >
              {label}
            </LabelBox>
          ) : null}
          <Headline
            columnAmount={nrOfProductsInRow}
            color={campaignImage?.colorTheme.font}
          >
            {title}
          </Headline>
        </HeadingMainInfoWrapper>
        <HeadingInfoWrapper columnAmount={nrOfProductsInRow}>
          {nrOfProductsInRow === GRID_COLUMNS.six ? (
            <Information color={campaignImage?.colorTheme.font}>
              {information}
            </Information>
          ) : null}
          <Link href={link?.url} color={campaignImage?.colorTheme.font}>
            {link?.displayName}
            <ArrowRight
              fontSize={isMobile ? 15 : 20}
              color={campaignImage?.colorTheme.font}
            />
          </Link>
        </HeadingInfoWrapper>
      </HeadingWrapper>
      <Container>
        <Slider
          items={products.map((product, index) => {
            const productMetaData: ProductMetaData = {
              list: "carousel",
              pageType,
              position: index,
            };

            return (
              <ProductCard
                key={product.code}
                PriceComponent={PriceComponents[index]}
                product={product as ProductDataSearch}
                productMetaData={productMetaData}
                selectedColumnsNumber={selectedColumnsNumber}
              />
            );
          })}
          navigation={isMobile ? "ARROWS_SMALL" : "ARROWS"}
          slidesConfig={{
            perView: slidesPerView,
            spacing: 10,
          }}
        />
      </Container>
    </CampaignCarouselWrapper>
  );
};
