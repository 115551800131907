import * as React from "react";
import type { LongTailTitleFacet } from "./LongTailTitle";
import { LongTailFacetType } from "./LongTailTitle";

export const LongTailTitleItem: React.FunctionComponent<LongTailTitleFacet> = ({
  value,
  url,
  type,
}) => {
  const className = `long-tail-title__${LongTailFacetType[type]}`;
  return (
    <>
      <span className={className}>
        {url !== undefined ? <a href={url}>{value}</a> : value}
      </span>
    </>
  );
};
