import type { CategoryInfo } from "@xxl/frontend-api";
import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import type { FacetList } from "../Search/SearchState";

export const hasAsideCampaignContent = (ipaperLink?: string): boolean => {
  return ipaperLink !== undefined && ipaperLink.length !== 0;
};

export const hasAsideCategoryContent = (
  categoryInfo: CategoryInfo | null
): boolean => {
  const subCategories = categoryInfo?.category?.subCategories;
  return Boolean(
    subCategories !== undefined
      ? subCategories.length > 0
      : categoryInfo?.category?.description
  );
};

export const hasAsideContent = (
  categoryInfo: CategoryInfo | null,
  ipaperLink?: string,
  facets?: FacetList
): boolean => {
  const hasAsideFacets = facets !== undefined && facets.length > 0;
  return (
    hasAsideCampaignContent(ipaperLink) ||
    hasAsideCategoryContent(categoryInfo) ||
    hasAsideFacets
  );
};

const ProductListWrapper: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <div
      className={`new-plp-filters category-list__product-list`}
      data-testid="new-plp-filters__product-list"
    >
      {children}
    </div>
  );
};

export { ProductListWrapper };
