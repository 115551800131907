import React from "react";
import { CategoryDescription as StyledCategoryDescription } from "./CategoryDescription.styled";
import { TruncateText } from "../../TruncateText/TruncateText";

type CategoryDescriptionProps = {
  description: string;
};

export const CategoryDescription: React.FunctionComponent<
  CategoryDescriptionProps
> = ({ description }) => (
  <StyledCategoryDescription
    className={`category-list__description test-category__description`}
  >
    <TruncateText numberOfVisibleRows={3} text={description} />
  </StyledCategoryDescription>
);
