import type { SearchRequest, SearchResponse } from "@xxl/search-api";
import { AbstractSearchFetchStrategy } from "./SearchFetchStrategy";
import type { State } from "./SearchState";
import type { AxiosResponse } from "axios";

export class PostSearchFetchStrategy extends AbstractSearchFetchStrategy {
  doFetchProducts = async (
    siteId: string,
    searchRequests: SearchRequest[],
    userId?: string
  ): Promise<AxiosResponse<SearchResponse>[]> => {
    const options = this.getFetchProductsQueryOptions(userId);
    return Promise.all(
      searchRequests.map((searchRequest) =>
        this.searchApi.search(siteId, searchRequest, options)
      )
    );
  };

  accepts = (_state: State): boolean => {
    return true;
  };
}
