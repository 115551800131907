import type { GuidePreviewContentData as FrontendGuidePreviewContentData } from "@xxl/frontend-api";
import type { GuidePreviewContentData as ContentApiGuidePreviewContentData } from "@xxl/content-api";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { ToggleFeature } from "../Common/FeatureToggle";
import { GuideTeaser } from "./GuideTeaser";
import { Slider } from "../Common/Slider";
import { useMediaQuery } from "@mui/material";
import {
  mobileAndTabletMediaQuery,
  smallMobileMediaQuery,
} from "../../utils/xxl-screen";
import { useClient } from "../../hooks/useClient/useClient";
import chunk from "lodash/chunk";
import { SlideWrapper } from "./GuideTeaser.styled";
import { getVisibleTeasersNumber } from "./GuideCarouselHelper";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type GuideCarouselProps = {
  guides:
    | FrontendGuidePreviewContentData[]
    | ContentApiGuidePreviewContentData[];
  showSideSpacing?: boolean;
  heading?: string;
};

export const GuideCarousel = ({
  guides,
  showSideSpacing = true,
  heading,
}: GuideCarouselProps) => {
  const { t } = useTranslations();
  const isClient = useClient();
  const isSmallMobile = useMediaQuery(smallMobileMediaQuery);
  const isMobileAndTablet = useMediaQuery(mobileAndTabletMediaQuery);
  const teasersVisible = getVisibleTeasersNumber({
    isSmallMobile,
    isMobileAndTablet,
  });
  let slideItems = [];

  if (teasersVisible < 4) {
    const chunkedItems = chunk<
      FrontendGuidePreviewContentData | ContentApiGuidePreviewContentData
    >(guides, teasersVisible);
    slideItems = chunkedItems.map((chunkArr, index) => {
      return (
        <SlideWrapper key={index}>
          {chunkArr.map((guide) => {
            return <GuideTeaser key={guide.url} {...{ guide }} />;
          })}
        </SlideWrapper>
      );
    });
  } else {
    slideItems = guides.map((guide) => (
      <GuideTeaser key={guide.url} {...{ guide }} />
    ));
  }

  if (!isClient) {
    return null;
  }

  return (
    <ToggleFeature flag="toggle_new_guides">
      {!isEmpty(guides) && (
        <section
          className={`guide-carousel component-standard-spacing${showSideSpacing ? " container" : ""}`}
          data-testid="guide-carousel"
        >
          <h2 className="component-standard-spacing__header">
            {isNotNullOrUndefined(heading)
              ? heading
              : t("guide.related.category.guides.title")}
          </h2>
          <Slider
            navigation={isMobileAndTablet ? "DOTS" : "ARROWS"}
            items={slideItems}
            mode="snap"
            slidesConfig={{
              perView: teasersVisible === 4 ? 4 : 1,
              spacing: 18,
            }}
          />
        </section>
      )}
    </ToggleFeature>
  );
};
