import React from "react";
import { SET_PAGE, PAGE_QUERY_PARAMETER } from "../Constants";
import { useSearchContext } from "../SearchState";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Pagination } from "next-js-app/src/components/Pagination";
// eslint-disable-next-line import/no-extraneous-dependencies
import { NR_OF_PRODUCTS_PER_PAGE } from "next-js-app/src/components/ProductListV2/constants";
import { DUMMY_URL } from "../../../constants";

const FetchMoreDataButton = ({
  scrollToRef,
}: {
  scrollToRef: HTMLElement | null;
}) => {
  const { state, dispatch } = useSearchContext();
  const { page, relativePageUrl } = state;
  const { isFetchingMoreProducts } = state;
  const { count = 0 } = state.searchData?.results ?? {};
  const currentPage = page > 1 ? page : 1;
  const pageCount = Math.ceil(count / NR_OF_PRODUCTS_PER_PAGE);

  const handlePaginationClick = (pageNo: number) =>
    dispatch({ type: SET_PAGE, payload: pageNo });

  const getRelativePagingUrl = (relativeUrl: string | null, pageNo: number) => {
    // Next provides a relativePageUrl based on the request to the server. If react-app, grab values from window.location.
    const tempUrl = new URL(
      `${DUMMY_URL}${
        relativeUrl ?? window.location.pathname + window.location.search
      }`
    );
    const pageForLink = pageNo > 1 ? pageNo : 1;
    const nextPage = pageForLink + 1;
    tempUrl.searchParams.set(PAGE_QUERY_PARAMETER, nextPage.toString());
    return tempUrl.toString().replace(DUMMY_URL.toLocaleLowerCase(), "");
  };

  const relativePagingUrl = getRelativePagingUrl(relativePageUrl, page);

  return (
    <>
      <Pagination
        relativePagingUrl={relativePagingUrl}
        page={currentPage}
        pageCount={pageCount}
        onChange={handlePaginationClick}
        disabled={isFetchingMoreProducts}
        scrollToRef={scrollToRef}
      />
    </>
  );
};

export { FetchMoreDataButton };
