import { useMediaQuery } from "@mui/material";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { Store } from "@xxl/content-api";
import type { HeroComponentData } from "@xxl/frontend-api";
import { ModuleDataTypeEnum } from "@xxl/frontend-api";
import { useInView } from "framer-motion";
import isEmpty from "lodash/isEmpty";
import type { FunctionComponent } from "react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelectedColumnsNumber } from "react-app/src/components/ProductList/hooks/useSelectedColumnsNumber";
import { CONTENT_NAMES } from "../../api/elevate-api";
import { useApiClients } from "../../contexts/ApiClients";
import { useSharedData } from "../../contexts/SharedData";
import { useTracking } from "../../contexts/Tracking";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { FullWidthDiv } from "../../styled/Common.styled";
import spaces from "../../styles/theme/spaces";
import {
  getPreferredStoresCookie,
  getXXLCookie,
  setPreferredStoresCookie,
} from "../../utils/Cookie";
import { isSearchPage } from "../../utils/xxl-page-type";
import { mobileMediaQuery } from "../../utils/xxl-screen";
import { stickyFiltersMarginSettings } from "../Filter/FilterBar/constants";
import { FilterBar } from "../Filter/FilterBar/FilterBar";
import { UPDATE_STICKY_FILTER, useFilterContext } from "../Filter/FilterState";
import { GuideCarousel } from "../guideCarousel/GuideCarousel";
import { CategoryPageHeroComponent } from "../HeroComponent/CategoryPageHeroComponent";
import { hasAsideContent, ProductListWrapper } from "../ProductList";
import { CategoryDescription } from "../ProductList/Description/CategoryDescription";
import { ProductList } from "../ProductList/ProductList";
import { SubCategoriesList } from "../Search/SubCategory/LegacySubCategoriesList";
import { AvailabilityBar } from "../Sort/AvailabilitySelector/AvailabilityBar";
import { useStores } from "../Sort/AvailabilitySelector/DialogStoreSelect/useStores";
import { BrandName } from "./BrandName/BrandName";
import { BrandList } from "./Brands/BrandList";
import { EmptySearchResult } from "./EmptySearchResult";
import { FaqList } from "./Faqs/FaqList";
import { FetchMoreDataButton } from "./FetchMoreDataButton";
import { GuidesTab } from "./GuidesTab/GuidesTab";
import { Header } from "./Header/Header";
import { useFetchProductListingSmallBannersData } from "./hooks/useFetchProductListingSmallBannersData";
import { useFilteredSmallBanners } from "./hooks/useFilteredSmallBanners";
import { StyledHeading } from "./SearchContent.styled";
import { getStoreIds, isOnlyProductCodes } from "./SearchContentHelper";
import { callSearchAndUpdateState } from "./SearchFetchProductsHelper";
import { AVAILABILITY } from "./SearchFetchProductsHelper.types";
import { SearchQueryResetButton } from "./SearchQueryResetButton";
import { useSearchContext } from "./SearchState";
import { SearchSuggestions } from "./SearchSuggestions";
import { SearchTabs } from "./SearchTabs";
import { StoresList } from "./StoresTab/StoresList";
import { HighlightedCategoriesList } from "./SubCategory/HighlightedCategoriesList";
import { useSubcategory } from "./SubCategory/subCategoryHelper";

type SearchProps = {
  isCampaignPage: boolean;
  isSearchResultPage: boolean;
  isBot: boolean;
};
const SearchContent: FunctionComponent<SearchProps> = ({
  isCampaignPage,
  isSearchResultPage,
  isBot,
}) => {
  const { t } = useTranslations();
  const { state, dispatch } = useSearchContext();
  const { pageBaseUrl, isInitialRequest } = state;
  const { state: filterState, dispatch: filterDispatch } = useFilterContext();
  const { isStickyFilterBar } = filterState;
  const hasOnlyProductCodes = isOnlyProductCodes(state.query);
  const selectedColumnsNumber = useSelectedColumnsNumber({
    initialNumberOfColumnsPerDeviceOnPlp: null,
  });
  const { featureToggles, siteUid } = useSharedData().data;
  const { searchApi } = useApiClients();
  const trackers = useTracking();
  const [selectedAvailability, setSelectedAvailability] = useState<
    AVAILABILITY[]
  >([]);
  const [selectedStoreIds, setSelectedStoreIds] = useState<string[]>([]);
  const scrollToRef = useRef(null);
  const { toggle_sticky_filter } = featureToggles;
  const {
    categoryContentsIds,
    ipaperLink,
    level1Categories,
    productListingSmallBannersIds,
  } = state.campaignData ?? {};
  const facets = state.searchData?.results?.facets;
  const {
    categoryId,
    categoryInfo,
    productListingSmallBanner,
    selectedCategoryCode,
    guides = [],
    highlightedCategories,
  } = state;

  const isMobile = useMediaQuery(mobileMediaQuery);
  const { storesData } = useStores();
  const activeTab =
    state.tabs?.find((tab) => tab.isActive)?.id ?? CONTENT_NAMES.products;

  const filterBarRef = useRef(null);
  const isFilterBarInView = useInView(
    filterBarRef,
    stickyFiltersMarginSettings
  );
  const { onCategoryClick } = useSubcategory();

  useEffect(() => {
    const { availability, ids } = getPreferredStoresCookie() ?? {};
    setSelectedAvailability(
      availability ?? [AVAILABILITY.ONLINE, AVAILABILITY.STORE]
    );
    setSelectedStoreIds(ids ?? []);
  }, []);

  useEffect(() => {
    filterDispatch({
      type: UPDATE_STICKY_FILTER,
      payload: !isFilterBarInView,
    });
  }, [isFilterBarInView, isStickyFilterBar, filterDispatch]);
  const isStickyFilter =
    toggle_sticky_filter && !isInitialRequest && !isFilterBarInView;

  const listHasAside = hasAsideContent(categoryInfo, ipaperLink, facets);
  const { count: numberOfProducts = 0, items } =
    state.searchData?.results ?? {};
  const filteredSmallBanners = useFilteredSmallBanners({
    categoryProductListingSmallBanner: categoryInfo?.productListingSmallBanner,
    level1Categories,
    productListingSmallBanner,
    productListingSmallBannersIds,
    selectedCategoryCode,
  });

  const heroComponentData: HeroComponentData | undefined =
    state.shopInShopCategoryData?.contentModules?.find(
      (module) => module.type === ModuleDataTypeEnum.HEROCOMPONENT
    );
  const frontendBase =
    typeof window !== "undefined" ? window.location.origin : pageBaseUrl ?? "";

  const onAvailabilityChange = useCallback(
    async (stores: Store[], availability: AVAILABILITY[]) => {
      setPreferredStoresCookie({
        availability,
        ids: getStoreIds(stores),
      });

      setSelectedAvailability(availability);
      setSelectedStoreIds(
        stores
          .filter((store) => isNotNullOrUndefined(store.id))
          .map((store) => store.id ?? "")
      );

      try {
        const cookie = await getXXLCookie();
        if (cookie === null || cookie.loopId === undefined) {
          throw Error("Could not get loopId.");
        }
        state.userGroups = cookie.userGroups;
        void callSearchAndUpdateState(
          dispatch,
          isSearchPage(),
          state,
          t,
          cookie.loopId,
          searchApi,
          siteUid,
          trackers
        );
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, searchApi, siteUid, state, t, trackers]
  );

  useFetchProductListingSmallBannersData({
    categoryContentsIds,
    categoryId,
    dispatch,
    frontendBase,
    level1Categories,
    productListingSmallBannersIds,
  });
  const { description = "" } = categoryInfo?.category ?? {};

  return (
    <>
      {state.hideHeader !== true && (categoryInfo !== null || isMobile) && (
        <Header />
      )}
      {heroComponentData !== undefined && (
        <CategoryPageHeroComponent {...heroComponentData} />
      )}
      {isSearchResultPage && (
        <div className="container">
          {!hasOnlyProductCodes && (
            <StyledHeading>
              {t("search.page.search.phrase")}&nbsp;{state.query}
            </StyledHeading>
          )}
          {state.searchSuggestions.length > 0 && (
            <SearchSuggestions
              makeSense={state.makeSense}
              searchSuggestions={state.searchSuggestions}
            />
          )}
        </div>
      )}
      <SearchTabs isSearchResultPage={isSearchResultPage}>
        {activeTab === CONTENT_NAMES.products && (
          <section
            className={`category-list container${
              listHasAside ? "" : " category-list--no-aside"
            }`}
          >
            {isMobile &&
              state.longTailTitle === null &&
              state.categoryId === undefined && (
                <BrandName name={state.brandData?.name} />
              )}
            {highlightedCategories !== undefined &&
              highlightedCategories.length > 0 && (
                <HighlightedCategoriesList
                  highlightedCategories={highlightedCategories}
                  style={{ marginBottom: spaces.smallRegular }}
                  onClick={onCategoryClick}
                />
              )}
            {categoryInfo?.category !== undefined && (
              <SubCategoriesList category={categoryInfo.category} />
            )}
            <FilterBar
              facets={null}
              isStickyFilter={isStickyFilter}
              longTailFacets={null}
              longTailPattern={null}
              selectedColumnsNumber={selectedColumnsNumber}
              selectedFilters={null}
              selectedSort={null}
              sortOptions={null}
              totalHits={numberOfProducts}
            />
            <AvailabilityBar
              numberOfProducts={numberOfProducts}
              onChange={onAvailabilityChange}
              searchQuery={state.query}
              selectedAvailability={selectedAvailability}
              selectedColumnsNumber={selectedColumnsNumber}
              selectedStoreIds={selectedStoreIds}
              version={1}
              storesData={storesData}
            />
            <FullWidthDiv id="js-filter-bar-container" ref={filterBarRef} />
            <ProductListWrapper>
              <div>
                {items !== undefined && !isEmpty(items) ? (
                  <>
                    <ProductList
                      products={items}
                      hasAside={listHasAside}
                      selectedColumnsNumber={selectedColumnsNumber}
                      smallBanners={filteredSmallBanners}
                      isPersonalized={!isBot}
                      ref={scrollToRef}
                    />
                    <FetchMoreDataButton scrollToRef={scrollToRef.current} />
                  </>
                ) : isCampaignPage ? (
                  <EmptySearchResult
                    heading={t("campaign.page.no.hits")}
                    text={t("campaign.page.choose.another.category")}
                  />
                ) : (
                  <EmptySearchResult
                    heading={t("filters.no.result.heading")}
                    text={t("filters.no.result")}
                  />
                )}
              </div>
            </ProductListWrapper>
          </section>
        )}
        {activeTab === CONTENT_NAMES.brand &&
          state.brandsTabContent !== undefined &&
          state.brandsTabContent.length > 0 && (
            <BrandList brands={state.brandsTabContent} />
          )}
        {activeTab === CONTENT_NAMES.guide &&
          state.guidesTabContent !== undefined &&
          state.guidesTabContent.length > 0 && (
            <GuidesTab guides={state.guidesTabContent} />
          )}
        {activeTab === CONTENT_NAMES.store &&
          state.storesTabContent !== undefined &&
          state.storesTabContent.length > 0 && (
            <StoresList stores={state.storesTabContent} />
          )}
        {activeTab === CONTENT_NAMES.faqEntry &&
          state.faqTabContent !== undefined && (
            <FaqList faqs={state.faqTabContent} />
          )}
      </SearchTabs>
      <SearchQueryResetButton />
      <GuideCarousel guides={guides} />

      {isMobile && (
        <div className="container component-standard-spacing">
          <CategoryDescription description={description} />
        </div>
      )}
    </>
  );
};

export { SearchContent };
