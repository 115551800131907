import { isEmpty } from "@xxl/common-utils";
import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import { Tabs, type TouchOrMouseEvent } from "../Common/Tabs";
import { TABS_CHANGE } from "./Constants";
import { useSearchContext } from "./SearchState";

type SearchTabProps = PropsWithChildren<{
  isSearchResultPage: boolean;
}>;

export const SearchTabs: FunctionComponent<SearchTabProps> = ({
  children,
  isSearchResultPage,
}) => {
  const { state, dispatch } = useSearchContext();
  const { tabs } = state;
  if (isEmpty(tabs)) {
    return null;
  }

  const handleTabChange = (event: TouchOrMouseEvent) => {
    const activeId = (event.target as Element).id;
    if (state.tabs !== null) {
      const activeItem = state.tabs.find((item) => item.isActive);

      if (activeItem !== undefined && activeItem.id !== activeId) {
        dispatch({
          type: TABS_CHANGE,
          payload: activeId,
        });
      }
    }
  };

  return isSearchResultPage ? (
    <Tabs
      handleTabChange={handleTabChange}
      tabs={tabs}
      tabsContainerClassName="container"
    >
      {children}
    </Tabs>
  ) : (
    <>{children}</>
  );
};
