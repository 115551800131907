import type {
  CategoryContentData,
  CategoryInfo,
  ProductListingSmallBannerData,
  ShopInShopCategoryData,
} from "@xxl/frontend-api";
import type { PimApi } from "@xxl/pim-api";
import type { Dispatch } from "react";
import type { Action } from "./SearchState";
import {
  BRAND_DATA_REQUEST_SUCCESS,
  CATEGORY_CONTENT_REQUEST_SUCCESS,
  CATEGORY_DATA_REQUEST_SUCCESS,
  PRODUCT_LISTING_SMALL_BANNERS_REQUEST_SUCCESS,
} from "./Constants";
import isEmpty from "lodash/isEmpty";

export const fetchCategoryContentData = async (
  ids: string[],
  dispatch: Dispatch<Action>
): Promise<void> => {
  try {
    const data = (await (
      await fetch(`/api/content/category/${ids.join(",")}`)
    ).json()) as CategoryContentData[];
    dispatch({
      type: CATEGORY_CONTENT_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchProductListingSmallBannersData = async (
  ids: string[],
  dispatch: Dispatch<Action>
): Promise<void> => {
  try {
    const data = (await (
      await fetch(`/api/content/product-listing-small-banners/${ids.join(",")}`)
    ).json()) as ProductListingSmallBannerData[];
    dispatch({
      type: PRODUCT_LISTING_SMALL_BANNERS_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchCategoryInfoData = async (categoryCode: string) =>
  (await (
    await fetch(`/api/content/category-info/${categoryCode}`)
  ).json()) as CategoryInfo;

export const fetchShopInShopCategoryData = async (
  categoryCode: string,
  dispatch: Dispatch<Action>
): Promise<void> => {
  try {
    const data = (await (
      await fetch(`/api/content/shopinshop-category/${categoryCode}`)
    ).json()) as ShopInShopCategoryData;

    dispatch({
      type: CATEGORY_DATA_REQUEST_SUCCESS,
      payload: data.categoryCode !== undefined ? data : undefined,
    });
  } catch (error) {
    console.error(error);
  }
};

export const fetchBrandData = async (
  siteId: string,
  brandCode: string,
  dispatch: Dispatch<Action>,
  pimApi: PimApi
): Promise<void> => {
  try {
    const { data } = await pimApi.getBrands(siteId, brandCode);
    if (isEmpty(data)) {
      throw new Error(`Empty data for brand ${brandCode}`);
    }
    dispatch({
      type: BRAND_DATA_REQUEST_SUCCESS,
      payload: data[0],
    });
  } catch (error) {
    console.error(error);
  }
};
