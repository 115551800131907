import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { LongTailTitleProps, LongTailTitleFacet } from "./LongTailTitle";
import { LongTailFacetType } from "./LongTailTitle";

const defaultFacetConfiguration: LongTailFacetType[] = [
  LongTailFacetType.brand,
  LongTailFacetType.category,
  LongTailFacetType.color,
  LongTailFacetType.size,
];

export const getLongTailTitlePartsInOrder = (
  longTailTitle: LongTailTitleProps,
  configuration: LongTailFacetType[]
): LongTailTitleFacet[] => {
  const result: (LongTailTitleFacet | null)[] = Array(
    Object.keys(longTailTitle).length
  ) as (LongTailTitleFacet | null)[];

  Object.keys(longTailTitle).forEach((key) => {
    const index: number = configuration.indexOf(
      LongTailFacetType[key as keyof typeof LongTailFacetType]
    );

    result.splice(index, 1, longTailTitle[key]);
  });

  return result.filter((part) => part !== null) as LongTailTitleFacet[];
};

export const getLongTailFacetConfiguration = (): LongTailFacetType[] => {
  return defaultFacetConfiguration;
};

export const brandChanged = (
  previousTitle: LongTailTitleProps | null,
  newBrand: LongTailTitleFacet
): boolean => {
  if (previousTitle === null) {
    return true;
  }
  const previousBrand = previousTitle.brand;
  return isNotNullOrUndefined(previousBrand)
    ? newBrand.value === previousBrand.value
    : true;
};
