import styled from "@emotion/styled/macro";
import { interactionFeedback, typographyToCss } from "../../styles/helpers";
import { ButtonStyledAsLink } from "../../styled";
import typography from "./typography";

const Button = styled(ButtonStyledAsLink)`
  align-items: center;
  display: flex;
  font-family: var(--font-family-medium);
  font-size: var(--space-small-regular);
  gap: 3px;
  margin-top: var(--space-small-regular);
  text-decoration: none;
  transition: color 0.2s;

  ${interactionFeedback(`
    color: var(--color-xxl-green);
  `)}
`;

const TextWrapper = styled.div<{
  numberOfVisibleRows: number;
  shouldTruncate: boolean;
}>`
  ${({ numberOfVisibleRows, shouldTruncate }) =>
    shouldTruncate
      ? `
    position: relative;
    display: block;
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${numberOfVisibleRows};
  `
      : ""}

  * {
    ${typographyToCss(typography)};
  }
`;

export { Button, TextWrapper };
