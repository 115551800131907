import type { Dispatch } from "react";
import { useEffect } from "react";
import {
  getLevel1CategoryIds,
  getLevel1SmallBannerIds,
} from "../SearchContentHelper";
import type { CampaignLevel1CategoryData } from "@xxl/frontend-api";
import {
  fetchCategoryContentData,
  fetchProductListingSmallBannersData,
} from "../SearchFetchCategoryContentHelper";
import type { Action } from "../SearchState";

const useFetchProductListingSmallBannersData = ({
  categoryContentsIds,
  categoryId,
  dispatch,
  frontendBase,
  level1Categories,
  productListingSmallBannersIds,
}: {
  categoryContentsIds?: string[];
  categoryId?: string;
  dispatch: Dispatch<Action>;
  frontendBase: string;
  level1Categories?: CampaignLevel1CategoryData[];
  productListingSmallBannersIds?: string[];
}) =>
  useEffect(() => {
    const level1CategoryContentIds =
      level1Categories !== undefined
        ? getLevel1CategoryIds(level1Categories)
        : [];
    const level1ProductListingBannerIds =
      level1Categories !== undefined
        ? getLevel1SmallBannerIds(level1Categories)
        : [];

    const allCategoryContentIds = [
      ...level1CategoryContentIds,
      ...(categoryContentsIds ?? []),
    ];
    const allProductListingSmallBannerIds = [
      ...level1ProductListingBannerIds,
      ...(productListingSmallBannersIds ?? []),
    ];

    const uniqueIds = [...new Set(allCategoryContentIds)];
    const uniqueBannerIds = [...new Set(allProductListingSmallBannerIds)];

    if (allCategoryContentIds.length > 0) {
      void fetchCategoryContentData(uniqueIds, dispatch);
    }

    if (allProductListingSmallBannerIds.length > 0) {
      void fetchProductListingSmallBannersData(uniqueBannerIds, dispatch);
    }
  }, [
    categoryContentsIds,
    frontendBase,
    level1Categories,
    dispatch,
    productListingSmallBannersIds,
    categoryId,
  ]);

export { useFetchProductListingSmallBannersData };
