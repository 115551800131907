import { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import * as React from "react";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { ListStyles } from "../PersonalizedProductList/personalized-product-list-helper";
import { PersonalizedProductList } from "../PersonalizedProductList/Wrapper";

const ErrorPage: React.FunctionComponent = () => {
  const { t } = useTranslations();
  return (
    <>
      <section className="container">
        <h1>{t("errors.server.title")}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: t("errors.server.subtitle"),
          }}
        ></p>
      </section>
      <div
        className="personalized-product-list component-standard-spacing container"
        data-testid="error-page-personalized-product-list"
      >
        <PersonalizedProductList
          recommendations={[
            {
              strategy: RecommendationsParametersStrategyEnum.personalized,
              productsCount: 8,
              listStyle: ListStyles.HORIZONTAL,
              recommendationsKey: "personalized-error-page",
            },
          ]}
        />
      </div>
    </>
  );
};
export { ErrorPage };
