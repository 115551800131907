import type { Action, State } from "./SearchState";
import isEmpty from "lodash/isEmpty";
import type { Dispatch } from "react";
import { HISTORY_NAVIGATE_BACK } from "./Constants";
import type { HistoryState } from "../../utils/History";
import { isNotNullOrUndefined } from "@xxl/common-utils";

export const shouldRenderEmptySearchResult = (state: State): boolean =>
  !isNotNullOrUndefined(state.campaignId) &&
  !isNotNullOrUndefined(state.categoryId) &&
  isEmpty(state.searchData?.results?.items) &&
  isEmpty(state.selectedFilters) &&
  isEmpty(state.brandsTabContent) &&
  isEmpty(state.guidesTabContent) &&
  isEmpty(state.storesTabContent) &&
  isEmpty(state.faqTabContent) &&
  !state.isFetchingNewSearchResult;

export const shouldRenderXXLLoader = (state: State): boolean => {
  if (state.isCampaignPage) {
    return state.searchData === null || state.campaignData === null;
  }
  return state.searchData === null;
};

export const onPopState =
  (dispatch: Dispatch<Action>) =>
  (event: PopStateEvent): void => {
    const state = event.state as HistoryState | null;
    if (state?.categoryId !== undefined) {
      dispatch({
        type: HISTORY_NAVIGATE_BACK,
        payload: state,
      });
    }
  };
