import type { CategoryData } from "@xxl/frontend-api";
import * as React from "react";
import {
  BackArrowStyled,
  SubCategoryLinkStyled,
} from "./SubCategoryLink.styled";
import { SubCategoriesListStyled } from "./SubCategoriesList.styled";
import { useSubcategory } from "./subCategoryHelper";
import { useSearchContext } from "../SearchState";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
type SubCategoriesListProps = {
  category: CategoryData;
};

export const SubCategoriesList: React.FunctionComponent<
  SubCategoriesListProps
> = ({ category }) => {
  const { t } = useTranslations();
  const { onCategoryClick } = useSubcategory();
  const { state } = useSearchContext();
  const { isFetchingCategoryData } = state;
  const parentCategory = category.breadcrumbs?.find(
    (item) =>
      item.categoryLevel !== undefined &&
      category.categoryLevel !== undefined &&
      item.categoryLevel === category.categoryLevel - 1
  );
  const showBackBtn = Boolean(
    category.categoryLevel !== undefined &&
      category.categoryLevel > 2 &&
      parentCategory !== undefined
  );
  return (
    <SubCategoriesListStyled
      data-testid="list-of-subcategories"
      isLoading={isFetchingCategoryData}
    >
      {showBackBtn && (
        <SubCategoryLinkStyled
          data-testid="subcategories-back"
          href={parentCategory?.url}
          onClick={onCategoryClick(
            parentCategory?.code,
            parentCategory?.categoryLevel
          )}
        >
          <BackArrowStyled />
          <span>{t("category.page.sub.categories.back")}</span>
        </SubCategoryLinkStyled>
      )}
      {category.subCategories?.map((subCategory) => (
        <SubCategoryLinkStyled
          href={subCategory.url}
          key={subCategory.code}
          onClick={onCategoryClick(subCategory.code)}
        >
          {subCategory.name}
        </SubCategoryLinkStyled>
      ))}
    </SubCategoriesListStyled>
  );
};
