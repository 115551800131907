import { getXXLCookie } from "../../utils/Cookie";
import { COOKIE_LOAD_SUCCESS } from "./Constants";
import { flushSync } from "react-dom";
import type { XXLCookie } from "../../global";
import { logError } from "../../utils/xxl-log";

export const fetchXXLCookie = () => {
  return fetch("/cookie/xxl");
};

export const dispatchXXLCookie = async (
  dispatch: (arg0: {
    type: typeof COOKIE_LOAD_SUCCESS;
    payload: NonNullable<XXLCookie>;
  }) => void,
  cookieVersion: string
): Promise<void> => {
  let payload: XXLCookie | null = null;

  try {
    payload = await getXXLCookie(cookieVersion);
  } catch (error: unknown) {
    logError(error);
  }

  // Using flushSync to make sure this event trigger a re-render in Search.tsx where a `useEffect` will fetch product list data
  // Otherwise batching logic would make the `useEffect` miss out on this change
  flushSync(() => {
    if (payload === null) {
      console.warn("Payload was null");
      return;
    }

    dispatch({
      type: COOKIE_LOAD_SUCCESS,
      payload,
    });
  });
};
