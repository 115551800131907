import styled from "@emotion/styled/macro";
import { resolutionSizes } from "../../../config";

export const CategoryDescription = styled.div`
  height: auto;
  @media (max-width: ${resolutionSizes.mobile}px) {
    margin-top: 0;
    margin-bottom: 23px;
  }
`;
