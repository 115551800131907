import * as React from "react";
import type { HeroComponentData } from "@xxl/frontend-api";
import { getImageUrl } from "../../utils/xxl-image";
import { Section } from "./CategoryPageHeroComponent.styled";

export const CategoryPageHeroComponent: React.FunctionComponent<
  HeroComponentData
> = ({
  backgroundColor,
  description,
  heading,
  image,
  textColor = "",
  withBackgroundColor,
}) => {
  if (
    heading === undefined &&
    description === undefined &&
    image?.baseUrl === undefined
  ) {
    return null;
  }
  const urlWithQueryParam =
    image?.baseUrl !== undefined ? getImageUrl(image) : "";
  const backgroundColorActive = withBackgroundColor ?? false;
  const activeBackgroundColor = backgroundColorActive
    ? backgroundColor?.value
    : undefined;

  return (
    <Section
      color={activeBackgroundColor}
      className={`container
        category-page-hero
        category-page-hero--${textColor.toLowerCase()}
        ${
          backgroundColorActive
            ? ""
            : "category-page-hero--force-black-text-on-mobile"
        } 
        category-page-hero--small
        ${
          backgroundColorActive
            ? `category-page-hero--with-background-color`
            : ""
        }`}
      style={{ backgroundColor: activeBackgroundColor }}
    >
      <div className="category-page-hero__details">
        <h1 className="category-page-hero__heading">{heading}</h1>
        <p className="category-page-hero__text-large">{description}</p>
      </div>
      {image?.baseUrl !== undefined && (
        <figure>
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet={`${urlWithQueryParam}&w=767&h=550`}
            />
            <source
              media="(max-width: 1080px)"
              srcSet={`${urlWithQueryParam}${
                backgroundColorActive ? "&w=767&h=550" : "&w=1220&h=550"
              }`}
            />
            <source
              media="(min-width: 1080px)"
              srcSet={`${urlWithQueryParam}${
                backgroundColorActive ? "&w=767&h=624" : "&w=1440&h=624"
              }`}
            />
            <img
              alt={`${image.alt ?? "The image couldn't be loaded"}`}
              src={`${urlWithQueryParam}${
                backgroundColorActive ? "&w=767&h=624" : "&w=1440&h=624"
              }`}
            />
          </picture>
        </figure>
      )}
    </Section>
  );
};
