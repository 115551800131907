import { hasValue } from "@xxl/common-utils";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { isValidV3CategoryDataBreadcrumb } from "react-app/src/components/Breadcrumbs/breadcrumbs-helper";
import { useSharedData } from "../../../contexts/SharedData";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { isCampaignPage } from "../../../utils/xxl-page-type";
import { Breadcrumbs } from "../../Breadcrumbs";
import { LongTailTitleComp } from "../LongTailTitleComp";
import { useSearchContext } from "../SearchState";
import { BuyingGuideHeaderCategoryHero } from "./BuyingGuideHeaderCategoryHero";
import {
  BreadcrumbsContainer,
  BuyingGuideContainer,
  H1,
  StyledHeader,
} from "./Header.styled";

export const Header: React.FunctionComponent = () => {
  const { t } = useTranslations();
  const { state } = useSearchContext();
  const { categoryInfo, isFetchingCategoryData, query, buyingGuideData } =
    state;
  const category = categoryInfo?.category;
  const { toggle_plp_buying_guides } = useSharedData().data.featureToggles;
  const homeUrl = typeof window === "undefined" ? "" : window.location.origin;
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");

  const headerText = hasValue(query)
    ? `${t("search.page.search.phrase")} ${query}`
    : categoryInfo?.category?.header;

  const showBuyingGuide = toggle_plp_buying_guides && buyingGuideData !== null;
  const categoryName = category?.header ?? category?.breadcrumbs?.at(-1)?.name;
  const shouldRenderHeader = !showBuyingGuide && isLaptop;

  return (
    <StyledHeader isLoading={isFetchingCategoryData}>
      <BreadcrumbsContainer className="container">
        <Breadcrumbs
          breadcrumbs={
            state.categoryInfo?.category?.breadcrumbs?.filter(
              isValidV3CategoryDataBreadcrumb
            ) ?? []
          }
          homeUrl={homeUrl}
          includeH1={true}
        />
      </BreadcrumbsContainer>
      {!isCampaignPage() && !state.isSearchResultPage && (
        <div className="container">
          {state.longTailTitle !== null &&
          category !== undefined &&
          category.name !== undefined ? (
            <LongTailTitleComp longTailTitle={state.longTailTitle} />
          ) : (
            shouldRenderHeader && <H1>{headerText}</H1>
          )}

          {category !== undefined &&
            isEmpty(state.longTailTitle) &&
            showBuyingGuide && (
              <BuyingGuideContainer>
                <BuyingGuideHeaderCategoryHero
                  buyingGuide={buyingGuideData}
                  categoryName={categoryName}
                />
              </BuyingGuideContainer>
            )}
        </div>
      )}
    </StyledHeader>
  );
};
