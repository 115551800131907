import type { ProductData as ProductDataSearch } from "@xxl/search-api";
import React from "react";
import { NewRibbon } from "./NewRibbon/NewRibbon";
import { PriceSplash } from "./Product.styled";
import { ProductRibbons } from "./ProductRibbons";

type ProductCardRibbons = {
  product: ProductDataSearch;
};
export const ProductCardRibbons: React.FunctionComponent<
  ProductCardRibbons
> = ({ product }) => {
  const { priceDisplay } = product;
  if (priceDisplay === undefined) {
    throw Error("Missing price display data");
  }

  return (
    <>
      {priceDisplay.priceSplash !== undefined && (
        <PriceSplash>
          <NewRibbon
            colorTheme={priceDisplay.colorTheme}
            label={priceDisplay.priceSplash}
            direction="left"
          />
        </PriceSplash>
      )}
      <ProductRibbons
        badges={product.badges ?? []}
        multiChannelAvailability={product.multiChannelAvailability ?? []}
      />
    </>
  );
};
