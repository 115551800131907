import type { BuyingGuide } from "@xxl/content-api";
import type {
  CampaignPageResult,
  CampaignPageResultV2,
  CategoryInfo,
  GuidePreviewContentData,
  LongTailData,
} from "@xxl/frontend-api";
import { PimApi, Configuration as PimApiConfiguration } from "@xxl/pim-api";
import { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import type { EntitySortingParameterCustomNameEnum } from "@xxl/search-api";
import React, { useEffect, useReducer } from "react";
import { useSharedData } from "../../contexts/SharedData";
import { useTracking } from "../../contexts/Tracking";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { hasDifference } from "../../utils/Object/object";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import { ListStyles } from "../PersonalizedProductList/personalized-product-list-helper";
import { PersonalizedProductList } from "../PersonalizedProductList/Wrapper";
import { XXLLoader } from "../XXLLoader";
import {
  CATEGORY_INFO_REQUEST_SUCCESS,
  QUERY_PARAMETER_ACTIONS,
  SEARCH_ROOT_ID,
  SET_SEARCH_RESULT_PAGE,
} from "./Constants";
import { dispatchXXLCookie } from "./CookieHelper";
import { EmptySearchResult } from "./EmptySearchResult";
import { useLoginCallback } from "./hooks/useLoginCallback";
import { useScrollRestoration } from "./hooks/useScrollRestoration";
import { useSearchApi } from "./hooks/useSearchApi";
import { useSortsForLoginStatus } from "./hooks/useSortsForLoginStatus";
import { brandChanged } from "./LongTailTitleHelper";
import { SearchContent } from "./SearchContent";
import { fetchCampaignData } from "./SearchFetchCampaignHelper";
import {
  fetchBrandData,
  fetchCategoryInfoData,
  fetchShopInShopCategoryData,
} from "./SearchFetchCategoryContentHelper";
import {
  callSearchAndUpdateState,
  createRequestId,
  handleSearchSuccess,
} from "./SearchFetchProductsHelper";
import type {
  AutoCompleteResponseAugmented,
  SearchResponseAugmented,
} from "./SearchFetchProductsHelper.types";
import {
  onPopState,
  shouldRenderEmptySearchResult,
  shouldRenderXXLLoader,
} from "./SearchHelper";
import {
  didUpdateSearchResult,
  enableAndAttachScrollEventToCampaignActionLinks,
  getScrollToPosition,
  preventBrowserScrollingRestoration,
  scrollToElement,
  scrollToPreviousPosition,
  shouldScrollToTopAfterFetchingNewData,
} from "./SearchScrollHelper";
import { getInitialState, reducer, SearchContext } from "./SearchState";
import {
  pushOrUpdatePageUrl,
  removeActionParamFromUrl,
} from "./SearchStateUrlHelper";

export type SearchProps = {
  brandCode?: string;
  brandName?: string;
  cacheableCampaignSearchQuery: boolean;
  campaignId?: string;
  campaignPageResult?: CampaignPageResult;
  campaignPageResultV2?: CampaignPageResultV2;
  categoryId?: string;
  categoryInfo: CategoryInfo | null;
  forceSolrAsProvider: boolean;
  hideHeader?: boolean;
  initialSort?: EntitySortingParameterCustomNameEnum;
  isInitialRenderFromServer: boolean;
  longTailFacets: LongTailData[];
  longTailPattern: string | null;
  page: number;
  pageBaseUrl: string | null;
  relatedGuides: GuidePreviewContentData[];
  relativePageUrl: string | null;
  requestTimeout?: number;
  searchData: SearchResponseAugmented | null;
  searchResultPage?: string;
  siteId: string;
  tabsResponse: AutoCompleteResponseAugmented | null;
  toggleStickyFilter?: boolean;
  togglePlpOneCardContentComponent: boolean;
  isBot: boolean;
  additionalCampaignIds?: string[];
  buyingGuideData: BuyingGuide | null;
  children?: React.ReactNode;
};

const Search: React.FunctionComponent<SearchProps> = ({
  requestTimeout,
  children,
  ...props
}) => {
  const [stateStruct, dispatch] = useReducer(reducer, getInitialState(props));
  const { t } = useTranslations();
  const previousState = stateStruct.prev;
  const state = stateStruct.current;
  const {
    categoryId,
    initialSorts: defaultSorts,
    isCampaignPage,
    isInitialRenderFromServer,
    longTailFacets,
    longTailPattern,
    page,
    provider,
    selectedFilters,
    selectedSort,
    userGroups,
    userId,
    isBot,
  } = state;

  const { data } = useSharedData();
  const trackers = useTracking();
  const { configuration, cookieVersion, siteUid } = data;
  const searchApi = useSearchApi({ provider, requestTimeout });
  const [pimApi] = React.useState(
    new PimApi(new PimApiConfiguration(configuration.pimApi))
  );
  const isSearchResultPage = props.searchResultPage === "true";
  const frontendBase =
    typeof window !== "undefined"
      ? window.location.origin
      : state.pageBaseUrl ?? "";

  if (
    isInitialRenderFromServer &&
    props.searchData !== null &&
    props.categoryInfo !== null
  ) {
    handleSearchSuccess(
      props.searchData,
      createRequestId(),
      dispatch,
      t,
      state,
      props.tabsResponse,
      trackers
    );

    dispatch({
      type: CATEGORY_INFO_REQUEST_SUCCESS,
      payload: props.categoryInfo,
    });
  }

  useEffect(() => {
    dispatch({
      type: SET_SEARCH_RESULT_PAGE,
      payload: isSearchResultPage,
    });
  }, [isSearchResultPage]);

  useEffect(() => {
    if (isBot) {
      return;
    }
    if (state.brandCode === undefined) {
      return;
    }
    void fetchBrandData(state.siteId, state.brandCode, dispatch, pimApi);
  }, [dispatch, isBot, pimApi, state.brandCode, state.siteId]);

  useEffect(() => {
    if (isBot) {
      return;
    }
    if (state.categoryId === undefined) {
      return;
    }
    const getCategoryInfoData = async (categoryId: string) => {
      try {
        const response = await fetchCategoryInfoData(categoryId);
        dispatch({
          type: CATEGORY_INFO_REQUEST_SUCCESS,
          payload: response,
        });
      } catch (error) {
        console.error("Could not fetch category info.");
      }
    };
    void getCategoryInfoData(state.categoryId);
  }, [dispatch, frontendBase, isBot, pimApi, state.categoryId, state.siteId]);

  useEffect(() => {
    if (isBot) {
      return;
    }
    if (props.campaignId === undefined) {
      return;
    }
    fetchCampaignData(frontendBase, props.campaignId, dispatch);
  }, [dispatch, frontendBase, isBot, props.campaignId]);

  useEffect(() => {
    if (
      !state.queryParameterActions.includes(
        QUERY_PARAMETER_ACTIONS.ScrollToProductList
      )
    ) {
      return;
    }
    void scrollToElement({ querySelector: `#${SEARCH_ROOT_ID}` });
    removeActionParamFromUrl();
  }, [state.queryParameterActions]);

  useEffect(() => {
    if (previousState !== undefined) {
      const { longTailTitle } = state;
      if (longTailTitle !== null && longTailTitle.brand !== null) {
        const { brand } = longTailTitle;

        if (
          brandChanged(previousState.longTailTitle, brand) &&
          previousState.longTailTitle?.brand?.url === undefined
        ) {
          if (isBot) {
            return;
          }
          void fetchBrandData(
            state.siteId,
            longTailTitle.brand.value,
            dispatch,
            pimApi
          );
        }
      }

      if (
        hasDifference(previousState, state, ["categoryId"]) &&
        !state.isHistoryBackEvent // Prevent url push if user navigated in browser history
      ) {
        pushOrUpdatePageUrl({
          ...state,
          categoryLevel: state.categoryInfo?.category?.categoryLevel,
          ...{ shouldUsePushNewUrl: true },
        });
      }
      if (
        didUpdateSearchResult(state, previousState) &&
        shouldScrollToTopAfterFetchingNewData()
      ) {
        const top = getScrollToPosition();
        window.scrollTo({
          behavior: "smooth",
          top,
        });
      }
      if (hasDifference(previousState, state, ["campaignData"])) {
        state.campaignId !== undefined &&
          state.campaignData?.categories !== undefined &&
          enableAndAttachScrollEventToCampaignActionLinks(
            state.campaignId,
            dispatch
          );
      }
    }

    if (
      userId !== undefined &&
      !state.isInitialRenderFromServer &&
      (previousState === undefined ||
        hasDifference(previousState, state, [
          "page",
          "provider",
          "categoryPath",
          "selectedFilters",
          "selectedSort",
          "userGroups",
          "userId",
          "categoryId",
        ]))
    ) {
      if (
        previousState !== undefined &&
        hasDifference(previousState, state, ["categoryId"]) &&
        state.categoryId !== undefined
      ) {
        const fetchAndDispatch = async (categoryIdString: string) => {
          try {
            const categoryInfoData =
              await fetchCategoryInfoData(categoryIdString);
            dispatch({
              type: CATEGORY_INFO_REQUEST_SUCCESS,
              payload: categoryInfoData,
            });
          } catch (error) {
            console.error(error);
          }
        };

        void fetchAndDispatch(state.categoryId);
      }

      void callSearchAndUpdateState(
        dispatch,
        isSearchResultPage,
        state,
        t,
        userId,
        searchApi,
        siteUid,
        trackers
      );
    }
  }, [
    previousState,
    state,
    props.campaignId,
    isSearchResultPage,
    t,
    searchApi,
    userId,
    pimApi,
    isBot,
    siteUid,
    frontendBase,
  ]);

  useEffect(
    () =>
      pushOrUpdatePageUrl({
        categoryId,
        longTailFacets,
        longTailPattern,
        page,
        selectedFilters,
        selectedSort,
        categoryLevel: state.categoryInfo?.category?.categoryLevel,
      }),
    [
      categoryId,
      longTailFacets,
      longTailPattern,
      page,
      selectedFilters,
      selectedSort,
    ]
  );

  useEffect(() => {
    if (isBot) {
      return;
    }
    state.categoryId !== undefined &&
      void fetchShopInShopCategoryData(state.categoryId, dispatch);
  }, [state.categoryId, frontendBase, isBot]);

  useEffect(() => {
    const hasLoadedCategoryContent = state.categoryContent;
    if (!state.isInitialRequest || hasLoadedCategoryContent !== null) {
      scrollToPreviousPosition();
    }
  }, [state.isInitialRequest, state.categoryContent]);

  useEffect(() => {
    const popStateCallback = onPopState(dispatch);
    window.addEventListener("popstate", popStateCallback);
    return (): void => window.removeEventListener("popstate", popStateCallback);
  }, [dispatch]);

  useEffect(() => {
    void dispatchXXLCookie(dispatch, cookieVersion);
    if ("next" in window) {
      return;
    }
    preventBrowserScrollingRestoration();
  }, [cookieVersion]);

  useLoginCallback({ dispatch });
  useScrollRestoration();
  useSortsForLoginStatus({ defaultSorts, dispatch, userGroups });

  return (
    <SearchContext.Provider value={{ state, dispatch }}>
      {state.isFinalSearchFailed ? (
        <ErrorPage />
      ) : shouldRenderXXLLoader(state) ? (
        <div className="hero-component__wrap">
          <XXLLoader />
        </div>
      ) : shouldRenderEmptySearchResult(state) ? (
        <EmptySearchResult
          heading={t("search.page.no.hits")}
          makeSense={state.makeSense}
          searchSuggestions={state.searchSuggestions}
        >
          <div
            className="personalized-product-list component-standard-spacing container"
            data-testid="search-no-hits-personalized-product-list"
          >
            <PersonalizedProductList
              recommendations={[
                {
                  strategy: RecommendationsParametersStrategyEnum.personalized,
                  productsCount: 16,
                  listStyle: ListStyles.VERTICAL,
                  recommendationsKey: "personalized-search-no-hits",
                  title: t("cart.empty.recommendations"),
                },
              ]}
            />
          </div>
        </EmptySearchResult>
      ) : (
        <>
          <SearchContent
            isBot={isBot}
            isSearchResultPage={isSearchResultPage}
            isCampaignPage={isCampaignPage}
          />
          {children}
        </>
      )}
    </SearchContext.Provider>
  );
};

export { Search };
