import { Configuration, SearchApi } from "@xxl/search-api";
import { useMemo } from "react";
import { useSharedData } from "../../../contexts/SharedData";
import type { SearchRequestProvider } from "../../../utils/data-types";

const shouldAddRequestTimeout = (provider: SearchRequestProvider) =>
  provider === "loop";

const useSearchApi = ({
  provider,
  requestTimeout,
}: {
  provider: SearchRequestProvider;
  requestTimeout?: number;
}) => {
  const {
    data: {
      configuration: { searchApi },
    },
  } = useSharedData();
  return useMemo(
    () =>
      new SearchApi(
        new Configuration({
          ...searchApi,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          baseOptions: {
            ...searchApi.baseOptions,
            timeout: shouldAddRequestTimeout(provider)
              ? requestTimeout
              : undefined,
          },
        })
      ),
    [provider, requestTimeout, searchApi]
  );
};

export { shouldAddRequestTimeout, useSearchApi };
