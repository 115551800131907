import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { Store } from "@xxl/content-api";
import type {
  CampaignLevel1CategoryData,
  CategoryContentData,
  ProductListingSmallBannerData,
} from "@xxl/frontend-api";
import { forceSolrAsProviderRegex } from "./SearchStateUrlHelper";

export const getLevel1CategoryIds = (
  level1Categories: CampaignLevel1CategoryData[]
): string[] => {
  return level1Categories.reduce((ids, it) => {
    return isNotNullOrUndefined(it.categoryContentsIds)
      ? [...ids, ...it.categoryContentsIds]
      : ids;
  }, [] as string[]);
};

export const getLevel1SmallBannerIds = (
  level1Categories: CampaignLevel1CategoryData[]
): string[] => {
  return level1Categories.reduce((ids, it) => {
    return isNotNullOrUndefined(it.productListingSmallBannersIds)
      ? [...ids, ...it.productListingSmallBannersIds]
      : ids;
  }, [] as string[]);
};

const SortContent = (
  contentArray: CategoryContentData[] | ProductListingSmallBannerData[],
  ids: string[]
): CategoryContentData[] | ProductListingSmallBannerData[] =>
  contentArray.sort(
    (a, b) => ids.indexOf(a.id ?? "1") - ids.indexOf(b.id ?? "1")
  );

export const getFilteredSmallBanners = (
  productListingSmallBanners: ProductListingSmallBannerData[],
  selectedCategoryCode: string | null,
  level1Categories?: CampaignLevel1CategoryData[],
  productListingSmallBannersIds?: string[]
): ProductListingSmallBannerData[] => {
  const getContentFilteredByIds = (
    productListingSmallBanners: ProductListingSmallBannerData[],
    contentIds: string[]
  ): ProductListingSmallBannerData[] => {
    return productListingSmallBanners.reduce(
      (previousContent, currentContent) => {
        const currentId =
          currentContent.id !== undefined ? currentContent.id : "";
        const existingCategoryContent = previousContent.find(
          (content) => content.id === currentId
        );
        const shouldBeAdded =
          contentIds.includes(currentId) &&
          existingCategoryContent === undefined;

        if (productListingSmallBannersIds !== undefined) {
          return SortContent(
            [...previousContent, ...(shouldBeAdded ? [currentContent] : [])],
            productListingSmallBannersIds
          );
        }

        return [...previousContent, ...(shouldBeAdded ? [currentContent] : [])];
      },
      [] as ProductListingSmallBannerData[]
    );
  };

  const idsOfContentInSelectedCategory =
    selectedCategoryCode !== null
      ? level1Categories?.find(
          (category) => category.code === selectedCategoryCode
        )?.productListingSmallBannersIds
      : undefined;

  const ids =
    idsOfContentInSelectedCategory ?? productListingSmallBannersIds ?? [];
  return getContentFilteredByIds(productListingSmallBanners, ids);
};

export const isOnlyProductCodes = (query: string): boolean => {
  const regexp = new RegExp(forceSolrAsProviderRegex);
  return regexp.test(query);
};

export const getStoreIds = (stores: Store[]) =>
  stores.reduce(
    (acc: string[], store) =>
      store.id === undefined ? acc : [...acc, store.id],
    []
  );
