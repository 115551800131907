import React from "react";
import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../../styles/helpers";

type BrandNameProps = {
  name?: string;
};

const H1 = styled.h1`
  ${typographyToCss({
    fontSize: 15,
    lineHeight: 1.2,
    tablet: {
      fontSize: 26,
    },
  })}
  margin: 0;

  ${MQ("tablet")} {
    margin-bottom: 32px;
  }
`;

export const BrandName: React.FunctionComponent<BrandNameProps> = ({
  name,
}) => {
  if (typeof name === "string" && name.length > 0) {
    return <H1>{name}</H1>;
  }
  return null;
};
